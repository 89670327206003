import { connect } from 'react-redux'
import Home from './Home'
import { 
    fetchUser,
    clearTags,
    clearCards,
    fetchUserStats,
    fetchHeatmap,
    fetchDecks,
    fetchDecksTree,
    fetchAllProgress,
    receiveActiveStudyProfile,
    createDeck,
    updateDeck,
    fetchImportStatus,
    removePendingImport,
    fetchStudyProfiles,
    fetchUserStudySettings,
    fetchUserSubscriptions,
    fetchUserProperties,
    updateUserProperties,
    deleteUserProperty,
    fetchCheckoutSessions,
    createPastStudySession,
    clearActiveStudySession,
    acceptCooleyTos
} from '../../actions/allActions'
import { studyProfilesArray } from '../../reducers/selectors';
import _ from 'lodash'

const mapStateToProps = state => {
    return {
        user: state.user,
        userProperties: state.userProperties,
        decks: _.get(state, 'decks', {}),
        decksTree: _.get(state, 'decksTree', []),
        pendingImports: state.pendingImports,
        studyProfilesArr: studyProfilesArray(state),
        studyProfiles: state.studyProfiles,
        heatmap: state.heatmap,
        activeStudySession: state.activeStudySession
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearTags: () => dispatch(clearTags()),
        clearCards: () => dispatch(clearCards()),
        createDeck: deck => dispatch(createDeck(deck)),
        fetchUser: () => dispatch(fetchUser()),
        fetchDecksTree: () => dispatch(fetchDecksTree()),
        fetchHeatmap: () => dispatch(fetchHeatmap()),
        fetchDecks: () => dispatch(fetchDecks()),
        fetchAllProgress: () => dispatch(fetchAllProgress()),
        fetchImportStatus: (job_id) => dispatch(fetchImportStatus(job_id)),
        fetchStudyProfiles: () => dispatch(fetchStudyProfiles()),
        fetchUserStudySettings: () => dispatch(fetchUserStudySettings()),
        fetchUserSubscriptions: () => dispatch(fetchUserSubscriptions()),
        fetchUserProperties: () => dispatch(fetchUserProperties()),
        updateUserProperties: properties => dispatch(updateUserProperties(properties)),
        deleteUserProperty: key => dispatch(deleteUserProperty(key)),
        removePendingImport: (job_id) => dispatch(removePendingImport(job_id)),
        setActiveStudyProfile: (activeStudyProfile) => dispatch(receiveActiveStudyProfile(activeStudyProfile)),
        updateDeck: deck => dispatch(updateDeck(deck)),
        fetchCheckoutSessions: () => dispatch(fetchCheckoutSessions()),
        createPastStudySession: (session) => createPastStudySession(session),
        clearActiveStudySession: () => dispatch(clearActiveStudySession()),
        acceptCooleyTos: () => dispatch(acceptCooleyTos()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
