import * as ApiUtil from '../util/apiUtil';

// Study Profile
export const RECEIVE_ACTIVE_STUDY_PROFILE = 'RECEIVE_ACTIVE_STUDY_PROFILE';
// Active Study Session
export const RECEIVE_ACTIVE_STUDY_SESSION = 'RECEIVE_ACTIVE_STUDY_SESSION';
export const CLEAR_ACTIVE_STUDY_SESSION = 'CLEAR_ACTIVE_STUDY_SESSION';
// Stats
export const RECEIVE_USER_STATS = 'RECEIVE_USER_STATS';
export const RECEIVE_HEATMAP = 'RECEIVE_HEATMAP';
// User
export const RECEIVE_USER = 'RECEIVE_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const RECEIVE_USER_SUBSCRIPTIONS = 'RECEIVE_USER_SUBSCRIPTIONS';
export const RECEIVE_USER_PROPERTIES = 'RECEIVE_USER_PROPERTIES';
// Shared Users
export const RECEIVE_SHARED_USERS = 'RECEIVE_SHARED_USERS';
// Cards
export const RECEIVE_USER_CARDS = 'RECEIVE_USER_CARDS';
export const RECEIVE_PAST_CARD = 'RECEIVE_PAST_CARD';
export const CLEAR_PAST_CARDS = 'CLEAR_PAST_CARDS';
export const UNDO_CARD_RESPONSE = 'UNDO_CARD_RESPONSE';
export const CLEAR_CREATED_CARDS = 'CLEAR_CREATED_CARDS';
export const CLEAN_UP_DELETED_CARD = 'CLEAN_UP_DELETED_CARD';
export const RECEIVE_UPDATED_CARD = 'RECEIVE_UPDATED_CARD';
export const RECEIVE_TAG_CARD = 'RECEIVE_TAG_CARD';
export const RECEIVE_UNTAG_CARD = 'RECEIVE_UNTAG_CARD';
// Decks
export const RECEIVE_SELECTED_DECK = 'RECEIVE_SELECTED_DECK';
export const RECEIVE_DECKS_TREE = 'RECEIVE_DECKS_TREE';
export const RECEIVE_CARDS = 'RECEIVE_CARDS';
export const RECEIVE_TAGS = 'RECEIVE_TAGS';
export const RECEIVE_SELECTED_TAGS = 'RECEIVE_SELECTED_TAGS';
export const RECEIVE_TAGGING_STRATEGY = 'RECEIVE_TAGGING_STRATEGY';
export const CLEAR_TAGS = 'CLEAR_TAGS';
export const CLEAR_CARDS = 'CLEAR_CARDS';
export const CLEAR_DECKS_TREE = 'CLEAR_DECKS_TREE';
export const RECEIVE_CREATED_CARDS = 'RECEIVE_CREATED_CARDS';
export const RECEIVE_CARD_AND_META = 'RECEIVE_CARD_AND_META';
export const RECEIVE_ALL_DECKS = 'RECEIVE_ALL_DECKS';
export const RECEIVE_DECKS = 'RECEIVE_DECKS';
export const RECEIVE_DECK = 'RECEIVE_DECK';
export const RECEIVE_ALL_PROGRESS = 'RECEIVE_ALL_PROGRESS';
export const RECEIVE_PERCENT_MASTERY = 'RECEIVE_PERCENT_MASTERY';
export const RECEIVE_DECK_PROGRESS = 'RECEIVE_DECK_PROGRESS';
export const RECEIVE_DECK_TOGGLE = 'RECEIVE_DECK_TOGGLE';
export const SHIFT_CARD = 'SHIFT_CARD';
export const RECEIVE_PENDING_IMPORT = 'RECEIVE_PENDING_IMPORT';
export const REMOVE_PENDING_IMPORT = 'REMOVE_PENDING_IMPORT';
export const CLEAR_PENDING_IMPORTS = 'CLEAR_PENDING_IMPORTS';
// Study Profile Settings
export const RECEIVE_STUDY_PROFILES = 'RECEIVE_STUDY_PROFILES';
export const RECEIVE_STUDY_PROFILE = 'RECEIVE_STUDY_PROFILE';
// Past Study Sessions
export const RECEIVE_PAST_STUDY_SESSIONS = 'RECEIVE_PAST_STUDY_SESSIONS';
// User Study Settings
export const RECEIVE_USER_STUDY_SETTINGS = 'RECEIVE_USER_STUDY_SETTINGS';
// Subscriptions
export const RECEIVE_CHECKOUT_SESSIONS = 'RECEIVE_CHECKOUT_SESSIONS';
// Loading
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
// Theme
export const SET_THEME = 'SET_THEME';
export const SET_MENU_STATE = 'SET_MENU_STATE';
// LOADING
export const START_TOGGLE_LOADING = 'START_TOGGLE_LOADING';
export const START_CARDS_LOADING = 'START_CARDS_LOADING';
export const START_PROGRESS_LOADING = 'START_PROGRESS_LOADING';
export const START_USER_STATS_LOADING = 'START_USER_STATS_LOADING';
// CLONING
export const RECEIVE_LAST_CLONE_TARGET = 'RECEIVE_LAST_CLONE_TARGET';
// LOGOUT
export const USER_LOGOUT = 'USER_LOGOUT';

// ---------------------------------------------
// Action Creators
// ---------------------------------------------

// Loading

export const startToggleLoading = payload => {
    return {
        type: START_TOGGLE_LOADING,
        payload
    }
}

export const startCardsLoading = payload => {
    return {
        type: START_CARDS_LOADING,
        payload
    }
}

export const startProgressLoading = payload => {
    return {
        type: START_PROGRESS_LOADING,
        payload
    }
}

export const startUserStatsLoading = payload => {
    return {
        type: START_USER_STATS_LOADING,
        payload
    }
}

// StudyProfile

export const receiveActiveStudyProfile = payload => {
    return {
        type: RECEIVE_ACTIVE_STUDY_PROFILE,
        payload
    }
}

// Active Session

export const receiveActiveStudySession = (payload) => {
    return {
        type: RECEIVE_ACTIVE_STUDY_SESSION,
        payload
    }
}

export const clearActiveStudySession = () => {
    return {
        type: CLEAR_ACTIVE_STUDY_SESSION
    }
}

// Stats

export const receiveUserStats = payload => {
    return {
        type: RECEIVE_USER_STATS,
        payload
    }
}

export const receiveHeatmap = payload => {
    return {
        type: RECEIVE_HEATMAP,
        payload
    }
}

// User

export const receiveUser = payload => {
    return {
        type: RECEIVE_USER,
        payload
    }
}

export const receiveUserSubscriptions = payload => {
    return {
        type: RECEIVE_USER_SUBSCRIPTIONS,
        payload
    }
}

export const receiveUserProperties = payload => {
    return {
        type: RECEIVE_USER_PROPERTIES,
        payload
    }
}

// Shared Users

export const receiveSharedUsers = payload => {
    return {
        type: RECEIVE_SHARED_USERS,
        payload
    }
}

// Cards

export const shiftCard = payload => {
    return {
        type: SHIFT_CARD,
        payload
    }
}

export const receiveUserCards = payload => {
    return {
        type: RECEIVE_USER_CARDS,
        payload
    }
}

export const receivePastCard = payload => {
    return {
        type: RECEIVE_PAST_CARD,
        payload
    }
}

export const clearPastCards = () => {
    return {
        type: CLEAR_PAST_CARDS,
    }
}

export const undoCardResponse = (payload) => {
    return {
        type: UNDO_CARD_RESPONSE,
        payload
    }
}

export const clearCreatedCards = () => {
    return {
        type: CLEAR_CREATED_CARDS
    }
}

export const cleanUpDeletedCard = (payload) => {
    return {
        type: CLEAN_UP_DELETED_CARD,
        payload
    }
}

export const receiveTagCard = (payload) => {
    return {
        type: RECEIVE_TAG_CARD,
        payload
    }
}

export const receiveUntagCard = (payload) => {
    return {
        type: RECEIVE_UNTAG_CARD,
        payload
    }
}

// Decks

export const receiveSelectedDeck = deckId => {
    return {
        type: RECEIVE_SELECTED_DECK,
        deckId
    }
}

export const receiveCardAndMeta = payload => {
    return {
        type: RECEIVE_CARD_AND_META,
        payload
    }
}

export const receiveCreatedCards = payload => {
    return {
        type: RECEIVE_CREATED_CARDS,
        payload
    }
}

export const receiveUpdatedCard = payload => {
    return {
        type: RECEIVE_UPDATED_CARD,
        payload
    }
}

export const receiveCards = payload => {
    return {
        type: RECEIVE_CARDS,
        payload
    }
}

export const receiveTags = payload => {
    return {
        type: RECEIVE_TAGS,
        payload
    }
}

export const receiveSelectedTags = payload => {
    return {
        type: RECEIVE_SELECTED_TAGS,
        payload
    }
}

export const receiveTaggingStrategy = payload => {
    return {
        type: RECEIVE_TAGGING_STRATEGY,
        payload
    }
}

export const clearTags = () => {
    return {
        type: CLEAR_TAGS
    }
}

export const clearCards = () => {
    return {
        type: CLEAR_CARDS
    }
}

export const clearUser = () => {
    return {
        type: CLEAR_USER
    }
}

export const clearDecksTree = () => {
    return {
        type: CLEAR_DECKS_TREE
    }
}

export const receiveAllDecks = payload => {
    return {
        type: RECEIVE_ALL_DECKS,
        payload
    }
}

export const receiveDecks = payload => {
    return {
        type: RECEIVE_DECKS,
        payload
    }
}

export const receiveDeck = payload => {
    return {
        type: RECEIVE_DECK,
        payload
    }
}

export const receiveDecksTree = payload => {
    return {
        type: RECEIVE_DECKS_TREE,
        payload
    }
}

export const receiveAllProgress = payload => {
    return {
        type: RECEIVE_ALL_PROGRESS,
        payload
    }
}

export const receiveDeckProgress = payload => {
    return {
        type: RECEIVE_DECK_PROGRESS,
        payload
    }
}

export const receivePercentMastery = payload => {
    return {
        type: RECEIVE_PERCENT_MASTERY,
        payload
    }
}

export const receiveDeckToggle = payload => {
    return {
        type: RECEIVE_DECK_TOGGLE,
        payload
    }
}

export const receivePendingImport = payload => {
    return {
        type: RECEIVE_PENDING_IMPORT,
        payload
    }
}

export const removePendingImport = payload => {
    return {
        type: REMOVE_PENDING_IMPORT,
        payload
    }
}

export const clearPendingImports = () => {
    return {
        type: CLEAR_PENDING_IMPORTS
    }
}

// Study Profile Settings

export const receiveStudyProfiles = payload => {
    return {
        type: RECEIVE_STUDY_PROFILES,
        payload
    }
}

export const receiveStudyProfile = payload => {
    return {
        type: RECEIVE_STUDY_PROFILE,
        payload
    }
}

// Past Study Sessions

export const receivePastStudySessions = payload => {
    return {
        type: RECEIVE_PAST_STUDY_SESSIONS,
        payload
    }
}

// Study Settings

export const receiveUserStudySettings = payload => {
    return {
        type: RECEIVE_USER_STUDY_SETTINGS,
        payload
    }
}

// Subscriptions

export const receiveCheckoutSessions = payload => {
    return {
        type: RECEIVE_CHECKOUT_SESSIONS,
        payload
    }
}

// Last Clone Target

export const receiveLastCloneTarget = payload => {
    return {
        type: RECEIVE_LAST_CLONE_TARGET,
        payload
    }
}

// Log Out
export const userLogout = () => {
    return {
        type: USER_LOGOUT
    }
}

// ---------------------------------------------
// API Wrappers
// ---------------------------------------------

// Stats 
export const fetchUserStats = () => dispatch => {
    dispatch(startUserStatsLoading());
    return ApiUtil.fetchUserStats().then(payload => {
        dispatch(receiveUserStats(payload))
        return payload;
    })
}

export const fetchHeatmap = () => dispatch => {
    return ApiUtil.fetchHeatmap().then(payload => {
        dispatch(receiveHeatmap(payload))
        return payload;
    })
}

export const fetchUser = () => dispatch => {
    return ApiUtil.fetchUser().then(payload => {
        dispatch(receiveUser(payload));
        return payload;
    })
}

export const updateUser = (user_id, attributes) => dispatch => {
    return ApiUtil.updateUser(user_id, attributes).then(payload => {
        dispatch(receiveUser(payload))
    })
}

export const deleteUser = (userId) => dispatch => {
    return ApiUtil.deleteUser(userId).then(payload => {
        if (payload.status === 'success') {
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success('Account successfully deleted.', toastConfig);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error('An error occurred during account deletion.', toastConfig);
        }
        return payload;
    })
}

export const fetchUserSubscriptions = () => dispatch => {
    return ApiUtil.fetchUserSubscriptions().then(payload => {
        dispatch(receiveUserSubscriptions(payload));
        return payload;
    })
}

export const fetchUserProperties = () => dispatch => {
    return ApiUtil.fetchUserProperties().then(payload => {
        dispatch(receiveUserProperties(payload));
        return payload;
    })
}

export const updateUserProperties = (properties) => dispatch => {
    return ApiUtil.updateUserProperties(properties).then(payload => {
        dispatch(receiveUserProperties(payload));
    })
}

export const deleteUserProperty = (key) => dispatch => {
    return ApiUtil.deleteUserProperty(key).then(payload => {
        dispatch(receiveUserProperties(payload));
    })
}

export const fetchSharedUsers = (deckId) => dispatch => {
    return ApiUtil.fetchSharedUsers(deckId).then(payload => {
        dispatch(receiveSharedUsers(payload))
    })
}

export const addSharedUser = (deckId, userObj) => dispatch => {
    return ApiUtil.addSharedUser(deckId, userObj).then(payload => {
        dispatch(receiveSharedUsers(payload))
    })
}

export const updateSharedUsers = (deckId, updates) => dispatch => {
    return ApiUtil.updateSharedUsers(deckId, updates).then(payload => {
        dispatch(receiveSharedUsers(payload))
    })
}

export const bulkUpdateDeckOwnershipOrder = (decks) => dispatch => {
    return ApiUtil.bulkUpdateDeckOwnershipOrder(decks).then(payload => {
        dispatch(fetchDecksTree());
    })
}

export const updatePassword = (current_password, new_password, new_password_confirmation) => dispatch => {
    return ApiUtil.updatePassword(current_password, new_password, new_password_confirmation)
}

export const updateUserName = (first_name, last_name) => dispatch => {
    return ApiUtil.updateUserName(first_name, last_name).then(payload => {
        dispatch(receiveUser(payload))
    })
}

export const impersonateUser = userId => dispatch => {
    return ApiUtil.impersonateUser(userId).then(payload => {
        return payload;
    })
}

export const stopImpersonatingUser = userId => dispatch => {
    return ApiUtil.stopImpersonatingUser(userId).then(payload => {
        return payload;
    })
}

export const referByEmail = (email) => dispatch => {
    return ApiUtil.referByEmail(email).then(payload => {
        return payload;
    })
}

// Schools

export const fetchSchools = () => dispatch => {
    return ApiUtil.fetchSchools().then(payload => {
        return payload;
    })
}

export const createSchool = school => dispatch => {
    return ApiUtil.createSchool(school).then(payload => {
        if (payload.status === 'success') {
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success(payload.message, toastConfig);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error(payload.message, toastConfig);
        }
    })
}

// Cards

export const createCards = card => dispatch => {
    return ApiUtil.createCards(card).then(payload => {
        dispatch(receiveCreatedCards(payload));
        dispatch(fetchAllProgress());
    })
}

export const updateCard = card => dispatch => {
    return ApiUtil.updateCard(card).then(payload => {
        dispatch(receiveUpdatedCard(payload));
    })
}

export const updateCardContent = card => dispatch => {
    return ApiUtil.updateCardContent(card).then(payload => {
        dispatch(receiveUpdatedCard(payload));
    })
}

export const updateCardResponse = (card, response) => {
    const retries = 1;
    return ApiUtil.updateCardResponse(card, response, retries);
}

export const reportCard = (cardId, feedback) => {
    return ApiUtil.reportCard(cardId, feedback);
}

export const tagCard = (cardId, tags) => dispatch => {
    dispatch(receiveTagCard({ cardId, tags }));
    return ApiUtil.tagCard(cardId, tags);
}

export const untagCard = (cardId, tags) => dispatch => {
    dispatch(receiveUntagCard({ cardId, tags }));
    return ApiUtil.untagCard(cardId, tags);
}

export const cloneCard = (cardId, targetDeckId) => dispatch => {
    return ApiUtil.cloneCard(cardId, targetDeckId).then(payload => {
        return payload;
    });
}

export const deleteCard = cardId => dispatch => {
    return ApiUtil.deleteCard(cardId).then(payload => {
        dispatch(cleanUpDeletedCard(payload));
    })
}

export const fetchUserCards = () => dispatch => {
    return ApiUtil.fetchUserCards().then(payload => {
        dispatch(receiveUserCards(payload))
    })
}

// Decks

export const createDeck = deck => dispatch => {
    return ApiUtil.createDeck(deck).then(payload => {
        dispatch(receiveDeck(payload));
        dispatch(fetchDecksTree());
        return payload;
    })
}

export const updateDeck = deck => dispatch => {
    return ApiUtil.updateDeck(deck).then(payload => {
        dispatch(receiveDeck(payload));
        dispatch(fetchDecksTree());
    })
}

export const resetDeck = deckId => dispatch => {
    return ApiUtil.resetDeck(deckId).then(payload => {
        dispatch(fetchAllProgress());
        dispatch(fetchDecks());
    })
}

export const cloneDeck = (deckId, options) => dispatch => {
    return ApiUtil.cloneDeck(deckId, options).then(payload => {
        dispatch(fetchDecksTree());
        dispatch(fetchAllProgress());
        dispatch(fetchDecks());
    })
}

export const browseCloneDeck = (targetDeckId, cloneCount) => dispatch => {
    return ApiUtil.browseCloneDeck(targetDeckId, cloneCount).then(payload => {
        if (payload.status === 'success') {
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success(payload.message, toastConfig);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error(payload.message, toastConfig);
        }
    })
}

export const saveCollapseDefaults = deckId => dispatch => {
    return ApiUtil.saveCollapseDefaults(deckId).then(payload => {
        if (payload.status === 'success') {
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success(payload.message, toastConfig);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error(payload.message, toastConfig);
        }
    })
}

export const setAsPaid = deckId => dispatch => {
    return ApiUtil.setAsPaid(deckId).then(payload => {
        dispatch(fetchDecks());
        if (payload.status === 'success') {
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success(payload.message, toastConfig);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error(payload.message, toastConfig);
        }
    })
}

export const invalidateProgressCaches = (deckId) => {
    return ApiUtil.invalidateProgressCaches(deckId);
}

export const deleteDeck = deckId => dispatch => {
    return ApiUtil.deleteDeck(deckId).then(payload => {
        dispatch(fetchDecksTree());
        dispatch(fetchDecks());
    })
}

export const deleteDeckOwnership = deckId => dispatch => {
    return ApiUtil.deleteDeckOwnership(deckId).then(payload => {
        dispatch(fetchDecksTree());
        dispatch(fetchDecks());
    })
}

export const toggleDeckCollapsed = (deckId, shouldLoad, isExpanding) => dispatch => {
    if (shouldLoad) dispatch(startToggleLoading({ deckId }));
    return ApiUtil.toggleDeckCollapsed(deckId, isExpanding).then(payload => {
        dispatch(receiveDeckToggle(payload));
        if (shouldLoad) {
            // Fetch sequentially so everything pops up at the same time
            dispatch(fetchAllProgress()).then(res => {
                dispatch(fetchDecksTree());
            });
        }
    })
}

export const assignSchool = (deckId, schoolId) => dispatch => {
    return ApiUtil.assignSchool(deckId, schoolId).then(payload => {
        if (payload.status === 'success') {
            dispatch(fetchDecks());
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success(payload.message, toastConfig);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error(payload.message, toastConfig);
        }
    })
}

export const fetchCards = (deckId, options) => dispatch => {
    dispatch(startCardsLoading());
    return ApiUtil.fetchCards(deckId, options).then(payload => {
        dispatch(receiveCards(payload));
        return payload;
    })
}

export const fetchTags = (deckId) => dispatch => {
    return ApiUtil.fetchTags(deckId).then(payload => {
        dispatch(receiveTags(payload))
    })
}

export const fetchDecks = () => dispatch => {
    return ApiUtil.fetchDecks().then(payload => {
        dispatch(receiveAllDecks(payload))
    })
}

export const fetchDecksTree = () => dispatch => {
    return ApiUtil.fetchDecksTree().then(payload => {
        dispatch(receiveDecksTree(payload))
    })
}

export const mergeDecks = (selected_deck_id, target_deck_id, retain_original) => dispatch => {
    return ApiUtil.mergeDecks(selected_deck_id, target_deck_id, retain_original).then(payload => {
        if (!!payload.success) {
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success('Deck merged.', toastConfig);
            reloadHomepageData(dispatch);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error('Deck merge failed. You may be offline, check your internet connection.', toastConfig);
        }
    })
}

export const fetchAllProgress = () => dispatch => {
    dispatch(startProgressLoading());
    return ApiUtil.fetchAllProgress().then(payload => {
        dispatch(receiveAllProgress(payload));
        return payload;
    })
}

export const fetchDeckProgress = (deckId, tags = null, taggingStrategy = null) => dispatch => {
    dispatch(startProgressLoading());
    return ApiUtil.fetchDeckProgress(deckId, tags, taggingStrategy).then(payload => {
        return dispatch(receiveDeckProgress(payload));
    })
}

export const fetchPercentMastery = deckId => dispatch => {
    return ApiUtil.fetchPercentMastery(deckId).then(payload => {
        dispatch(receivePercentMastery(payload));
    })
}

export const importDeck = deckName => (dispatch) => {
    return ApiUtil.importDeck(deckName).then(payload => {
        if (!!payload.job_id) dispatch(receivePendingImport({ name: deckName, jid: payload.job_id }));
        return payload;
    })
}

export const fetchImportStatus = job_id => dispatch => {
    return ApiUtil.fetchImportStatus(job_id).then(payload => {
        if (payload.status == 'complete') {
            if (window.location.hash === '#/') {
                reloadHomepageData(dispatch);
            }
            dispatch(removePendingImport(job_id));
        } else if (payload.status == 'failed') {
            dispatch(removePendingImport(job_id));
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined }
            window.toast.error('Deck import failed. Contact support@synaptiq.co for support.', toastConfig);
        }
        return payload.status;
    })
}

const reloadHomepageData = dispatch => {
    dispatch(fetchDecksTree());
    dispatch(fetchDecks());
    dispatch(fetchAllProgress());
}

// Study Profile Settings
export const fetchStudyProfiles = () => dispatch => {
    return ApiUtil.fetchStudyProfiles().then(payload => {
        dispatch(receiveStudyProfiles(payload))
    })
}

export const updateStudyProfile = (studyProfile) => dispatch => {
    return ApiUtil.updateStudyProfile(studyProfile).then(payload => {
        dispatch(receiveStudyProfile(payload));
        return payload;
    })
}

// Past Study Sessions
export const fetchPastStudySessions = () => dispatch => {
    return ApiUtil.fetchPastStudySessions().then(payload => {
        dispatch(receivePastStudySessions(payload))
    })
}

export const createPastStudySession = (session) => {
    return ApiUtil.createPastStudySession(session);
}

// User Study Settings
export const fetchUserStudySettings = () => dispatch => {
    return ApiUtil.fetchUserStudySettings().then(payload => {
        dispatch(receiveUserStudySettings(payload))
    })
}

export const updateUserStudySettings = (userStudySettings) => dispatch => {
    return ApiUtil.updateUserStudySettings(userStudySettings).then(payload => {
        dispatch(receiveUserStudySettings(payload));
    })
}

// AWS
export const fetchPresignedUrl = (bucket, dirName, filename) => {
    return ApiUtil.fetchPresignedUrl(bucket, dirName, filename)
}

// Curation / Metal
export const fetchSemanticSearchResults = (searchValue) => {
    return ApiUtil.fetchSemanticSearchResults(searchValue);
}

export const curatorClone = (targetDeckId, cardIds) => dispatch => {
    return ApiUtil.curatorClone(targetDeckId, cardIds).then(payload => {
        if (payload.status === 'success') {
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success(payload.message, toastConfig);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error(payload.message, toastConfig);
        }
    });
}

// Datatables
export const fetchBrowseFilters = () => dispatch => {
    return ApiUtil.fetchBrowseFilters().then(payload => {
        return payload?.filters;
    })
}

// CardSuspends
export const suspendCard = cardId => dispatch => {
    return ApiUtil.suspendCard(cardId).then(payload => {
        if (payload.status === 'success') {
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success(payload.message, toastConfig);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error(payload.message, toastConfig);
        }
    })
}

// DeckSuspends
export const suspendDeck = deckId => dispatch => {
    return ApiUtil.suspendDeck(deckId).then(payload => {
        if (payload.status === 'success') {
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success(payload.message, toastConfig);
            reloadHomepageData(dispatch);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error(payload.message, toastConfig);
        }
    })
}

export const unSuspendCard = cardId => dispatch => {
    return ApiUtil.unSuspendCard(cardId).then(payload => {
        if (payload.status === 'success') {
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success(payload.message, toastConfig);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error(payload.message, toastConfig);
        }
    })
}

export const unSuspendDeck = deckId => dispatch => {
    return ApiUtil.unSuspendDeck(deckId).then(payload => {
        if (payload.status === 'success') {
            const toastConfig = { position: "bottom-left", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.success(payload.message, toastConfig);
            reloadHomepageData(dispatch);
        } else {
            const toastConfig = { position: "bottom-left", hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined }
            toast.error(payload.message, toastConfig);
        }
    })
}

// Checkout
// export const fetchCheckoutSession = (deckId) => dispatch => {
//     return ApiUtil.fetchCheckoutSession(deckId).then(payload => {
//         return payload.checkoutSession;
//     })
// }

export const fetchCheckoutSessions = (product_id) => dispatch => {
    return ApiUtil.fetchCheckoutSessions(product_id).then(payload => {
        dispatch(receiveCheckoutSessions(payload));
        return payload.checkoutSessions;
    })
}

export const startFreeTrial = () => dispatch => {
    return ApiUtil.startFreeTrial().then(payload => {
        dispatch(receiveUser(payload));
    })
}

export const redeemAccessCode = (accessCode) => dispatch => {
    return ApiUtil.redeemAccessCode(accessCode).then(payload => {
        if (payload.status === 'success') {
            dispatch(fetchUserProperties());
            dispatch(fetchDecksTree());
            dispatch(fetchAllProgress());
            dispatch(fetchDecks());
        }
        return payload;
    })
}

export const fetchBillingPortal = () => dispatch => {
    return ApiUtil.fetchBillingPortal().then(payload => {
        return payload.billingPortal;
    })
}

export const postCheckoutSuccess = () => dispatch => {
    return ApiUtil.postCheckoutSuccess().then(payload => {
        return payload;
    })
}

// Theme
export const setTheme = payload => {
    return {
        type: SET_THEME,
        payload
    }
}

export const setMenuState = payload => {
    return {
        type: SET_MENU_STATE,
        payload
    }
}

export const acceptCooleyTos = () => dispatch => {
    return ApiUtil.acceptCooleyTos().then((payload) => {
       return payload
    });
}
